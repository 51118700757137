import * as React from 'react'

import { WishlistMenuItem } from '@grandvisionhq/fun-wishlist/menu-item'
import { MenuItemType } from '@grandvisionhq/layout'
import { CmsLayoutSettings, withCmsLayout } from '@grandvisionhq/next'

import '@grandvisionhq/my-account/dist/index.css'
import '@grandvisionhq/fun-wishlist/styles.css'

export const settings: CmsLayoutSettings = {
  useAlternativeLogoInFooter: false,
  // disabling cart button (ecommerce disabled for pt-grandoptical at the moment)
  renderCartButton: (item: MenuItemType) => <React.Fragment key={item.label} />,
  renderWishlistMenuItem: (item: MenuItemType, tabIndex?: number) => {
    const props = {
      key: item.label || 'wishlist-button-header',
      label: item.label,
      className: 'header-menu__icon',
      tabIndex,
    }

    return <WishlistMenuItem {...props} />
  },
}

export default withCmsLayout(settings)
